import {useRive, Layout, Fit, Alignment} from '@rive-app/react-canvas';

const RiveAnimation = () => {
  const {RiveComponent} = useRive({
    src: '/rive_assets/get_started_for_blogpost.riv',
    autoplay: true,
    layout: new Layout({
      fit: Fit.Contain,
      alignment: Alignment.TopCenter,
    }),
  });

  return <RiveComponent />;
};

export default RiveAnimation;
